.social-media-ticker{
    animation: linear 4s infinite alternate fade;
    font-family: var(--title-font);
    font-size: 2em;
    position: absolute;
    right: 20px;
    top: 40px;
}

.social-media-ticker svg {
  position: relative;
  filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
}

.social-media-ticker span {
    color: var(--title-color);
    font-family: var(--body-font);
    font-weight: 400;
    margin-left: 12px;
    position: relative;
    text-transform: uppercase;
    filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
}
