@keyframes fade {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }
}

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
  
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

@keyframes pulse {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.6;
    }
}