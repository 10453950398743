.countdown-timer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 80;
}

.curtains {
    animation: gradient 2s ease infinite;
    background-image: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 50;
}

.countdown-timer h3 {
    color: var(--title-color);
    font-family: var(--title-font);
    font-size: 64px;
    filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
    margin: 400px auto 0;
    position: relative;
    text-align: center;
    width: 100%;
}

.countdown-timer p {
    color: var(--title-color);
    font-family: var(--title-font), sans-serif;
    font-size: 46px;
    position: relative;
    margin: 20px auto;
    text-align: center;
    width: 160px;
    filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
}

.curtains .waves {
    position: absolute;
    bottom: -5px;
    opacity: 0.5;
    width: 100%;
}

.curtains .waves div {
    position: absolute;
    bottom: 0;
}
