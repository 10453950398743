.header-logo {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
}

.header-logo .logo-image {
  position: absolute;
  height: 128px;
  width: 400px;
  top: 40px;
  left: 40px;
}

.header-logo .logo-image img {
  filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
  position: relative;
  max-height: 100%;
  width: 100%;
}