.neonTitles {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    z-index: 100;
}

.neonTitles .title {
    animation: 1s ease 0s infinite alternate pulse;
    color: transparent;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 196px;
    font-style: italic;
    opacity: 0;
    -webkit-text-fill-color: #00000020;
    -webkit-text-stroke-width: 4px;
}