:root {
    --burgundy: #75001aff;
    --portland-orange: #f46036ff;
    --medium-spring-green: #52ffb8ff;
    --midnight-green-eagle-green: #114b5fff;
    --pink: #ffcad4ff;
    --vivid-blue: #0094ffff;
    --fake-black: #111;
    --twitch-purple: #9146ffff;
    --youtube-red: #ff0000ff;
}