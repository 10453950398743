.counter {
    color: var(--title-color);
    font-family: var(--title-font), cursive;
    font-size: 2.5em;
    font-weight: 400;
    font-style: normal;
    position: absolute;
    bottom: 30px;
    left: 40px;
}

.counter span {
    filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
    position: relative;
}

.counter small {
    filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
    font-size: 0.5em;
    display: block;
    line-height: 0.2;
}