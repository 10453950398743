.qrcode {
  background-color: var(--fake-black);
  color: var(--title-color);
  filter: drop-shadow(-2px -2px 4px rgba(0,0,0,0.4));
  font-family: var(--title-font);
  font-weight: bold;
  font-style: normal;
  position: absolute;
  text-transform: uppercase;
  bottom: 0;
  right: 0;
  padding: 20px 50px;
  border-top-left-radius: 50px;
  text-align: center;
}

.qrcode .text {
  filter: drop-shadow(-2px -2px 4px rgba(0,0,0,0.2));
  font-size: 2em;
  position: relative;
  margin: 0 auto 20px auto;
  text-align: center;
  width: 200px;
}

.qrcode .qr-container {
  background-image: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  border-radius: 10%;
  padding: 10px;
}

.qrcode canvas {
  border-radius: 10%;
  position: relative;
  height: 200px;
  width: 200px;
}