.cinematic-bars {
  position: fixed;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
}

.cinematic-bars__bar {
  animation: gradient 10s ease infinite;
  position: absolute;
  left: 0;
  width: 100%;
  height: 128px;
  background-color: #050505;
  background-size: 150% 150%;
}

.cinematic-bars__bar {
  background-image: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
}

.cinematic-bars__bar--top {
  top: 0;
}

.cinematic-bars__bar--bottom {
  bottom: 0;
}